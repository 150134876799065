import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageSection from "../../components/page-section"

const TermsOfServiceSchoolPage = () => {

  return (
    <Layout>
      <Seo title="Regulamin rejsów" />
      <PageSection>
        <h1 className="pb-3">Regulamin rejsów dla szkół</h1>
      </PageSection>
      <PageSection className="bg-light text-black">
        <h3>Regulamin bezpłatnych rejsów “Lato w mieście”.</h3>
        <h5 className="my-5 fw-bold">§1. Organizator Cyklu Bezpłatnych Rejsów</h5>
        <p>1. Organizatorem, koordynatorem Cyklu Bezpłatnych Rejsów Stołecznej Żeglugi Pasażerskiej “Lato w mieście” jest Rosa Venti Sp. z o. o. ul. Nowy Świat 33 / 13, 00-029 Warszawa NIP: 525-281-76-48 na zlecenie Zarządu Zieleni w Warszawie.</p>
        <p>2. Organizator współpracuje przy realizacji projektu z armatorami wynajmującymi jednostki, posiadające odpowiednie aktualne dokumenty, oraz wykorzystuje własne jednostki.</p>
        <h5 className="my-5 fw-bold">§2. Uczestnicy</h5>
        <p>1. Rejsy przewidziane są dla uczestników stołeczenej Akcji Lato w mieście (maksymalnie 6 klasa szkoły podstawowej).</p>
        <p>2. Za uczestników rejsu odpowiedzialni są opiekunowie.</p>
        <h5 className="my-5 fw-bold">§3. Rejs</h5>
        <p>1. Za rejs rozumiana jest 1 godzinna (w tym czynności przygotowawcze do rejsu) wycieczka po Wiśle różnymi jednostkami przystosowanymi do żeglugi pasażerskiej po Wiśle.</p>
        <p>2. Rejs rozpoczyna się w 3 lokalizacjach: Przystań Młociny, Dworzec Wodny Warszawa, oraz Plaża Romantyczna.</p>
        <div className="ms-5">
          <p>2.1. Rejsy odbywać się będą w lipcu od poniedziałku do piątku z Dworca Wodnego Warszawa lub Przystani Młociny oraz w dniach 8-12 sierpnia z Plaży Romantycznej.</p>
          <p>2.2. Do dyspozycji uczestników będzie 60 rejsów: 54 rejsy odbywające się z Dworca Wodnego Warszawa (przy metrze Centrum Nauki Kopernik), oraz z przystani Młociny, a także 6 rejsów odbywających się z Plaży Romantycznej (przewidziane w terminie 8-12 sierpnia.)</p>
        </div>
        <p>3. Terminy rejsów będą ustalane z koordynatorem, do wyczerpania puli rezerwacji.</p>
        <p>4. W jednym, godzinnym rejsie, może wziąć udział max 11 uczestników (wliczając w to opiekunów). W przypadku większej grupy możliwa jest rezerwacja kilku rejsów z rzędu.</p>
        <p>5. W każdym rejsie bierze udział przewodnik.</p>
        <p>6. Na miejsce rozpoczęcia rejsu należy stawić się min. 10 minut przed planowanym rejsem. Spóźnienie grupy spowoduje skrócenie rejsu.</p>
        <h5 className="my-5 fw-bold">§4. Rezerwacje</h5>
        <p>1. Rezerwacje odbywają się tylko i wyłącznie na stronie <a href="/">rejsy.waw.pl</a> w zakładce szkoły. </p>
        <p>2. Rezerwacja i udział w rejsach jest bezpłatny.</p>
        <p>3. Po wypełnieniu rejestracji w ciągu trzech dni roboczych z Uczestnikiem skontaktuje się telefonicznie koordynator, który potwierdzi zakwalifikowanie Uczestnika na rejs, oraz potwierdzi lokalizację oraz termin rejsu. (Organizator zastrzega sobie wydłużony czas potwierdzenia rezerwacji w pierwszym tygodniu otwartych rezerwacji tj. do 1 czerwca)</p>
        <h5 className="my-5 fw-bold">§5. Odwołanie, zmiany rezerwacji</h5>
        <p>1. Odwołanie oraz zmiana rezerwacji odbywa się poprzez maila: <a href="mailto:szkoly@rejsy.waw.pl">szkoly@rejsy.waw.pl</a></p>
        <p>2. Rejsy po stronie organizatora odwoływane są jedynie w przypadku warunkach, przy których żegluga mogłaby być niebezpieczna: wysoki stan wód, wiatr, alerty RCB.</p>
        <p>3. W wyniku odwołania rejsu w wyniki sił wyższych, ustalany jest z uczestnikami nowy termin rezerwacji bądź odwołanie rejsu. </p>
        <p>4. <strong>Pogoda nie jest powodem do odwoływania rezerwacji</strong>. W deszczowe, zimne dni możliwa jest zmiana jednostki przez organizatora na taką, która posiada zadaszenie / ogrzewanie.</p>
        <p>5. W przypadku odwołania rejsu przez Uczestnika, prosimy o niezwłoczne poinformowanie Koordynatora, aby nie blokować miejsc dla innych chętnych Uczestników.</p>
        <h5 className="my-5 fw-bold">§6. Pytania, uwagi</h5>
        <p>1. Wszystkie pytania, uwagi prosimy kierować na adres mailowy: <a href="mailto:szkoly@rejsy.waw.pl">szkoly@rejsy.waw.pl</a></p>
      </PageSection>
    </Layout >
  )
}

export default TermsOfServiceSchoolPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;